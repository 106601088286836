/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: #000;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
}
.navbar {
  background: rgba(7, 7, 7, 0.75);
  /* Neutral/Neutral 01 */

  border-bottom: 1px solid #2c3a4b;
  backdrop-filter: blur(25px);
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar {
  background-color: #00000035 !important;
  // backdrop-filter: blur(5px) !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.726) !important;
  z-index: 1;
}
.nav-link {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 170%;
  transition: all 0.4s;
  position: relative;
  display: block;
  color: #6d7580 !important;
  letter-spacing: 0.2px !important;
}
.nav-link:hover {
  color: #fff !important;
}
.active {
  color: #fff !important;
  border-bottom: 3.5px solid #e730a8;
}
body {
  user-select: none;
  scroll-behavior: smooth;
  background: url("./BG.svg") no-repeat center, #27272a !important;
  background-size: cover !important;
}
.nav-btn {
  padding: 8px 16px;
  gap: 10px;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  /* identical to box height, or 24px */
  letter-spacing: 0.008em;

  /* Neutral/Neutral 05 */

  color: #858c94;
  /* Neutral/Neutral 05 */

  border: 0.5px solid #858c94;
  border-radius: 100px;
  svg {
    font-size: 20px;
  }
}
a {
  text-decoration: none !important;
}
.App {
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    margin-top: 25px;
    text-align: center;
    font-size: 36.0477px;
    line-height: 47px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(90deg, #fa359e 0%, #811cd4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.footer-bg {
  padding-top: 30px;
  margin-top: 100px;
  video {
    object-fit: cover;
    width: 100%;
    height: 310px;
    position: absolute;
    bottom: 2;
    left: 0%;
  }
  .footer-ab-content {
    position: absolute;
    backdrop-filter: blur(7px);
    width: 100% !important;
    background: linear-gradient(180deg, #000001 0%, rgba(0, 0, 1, 0.44) 100%);
    left: 0;
    // right: 0
    padding: 0px 40px;
  }
  h3 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
  li {
    a {
      font-family: "poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #858c94;
    }
  }
  svg {
    color: #a5abb3;
  }
  hr {
    height: 2px;
    background-color: #a5abb3;
    margin-top: 8px;
  }
  p {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #545d69;
  }
}

.footer-img {
  margin-top: -80px;
  margin-bottom: 30px;
}
